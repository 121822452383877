import Link from "next/link"
import React, {useContext} from "react"
import DialogsContext from "@/components/provider/DialogProvider/DialogsContext";
import styles from './feedback.module.scss'

export default function Feedback() {
    const {openDialog} = useContext(DialogsContext)

    const handleShow = () => {
        openDialog({
            content: (close: any) => {
                return <DialogFeedback close={close} />
            },
            size: [300, 'auto'],
            position: 'bottom'
        })
    }

    return <div className={styles['help-btn']} onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="21" viewBox="0 0 12 21" fill="none">
            <path d="M4.2298 17.0985C4.30364 16.9937 4.38379 16.8936 4.4698 16.7985C4.65572 16.6111 4.87692 16.4623 5.12064 16.3607C5.36435 16.2592 5.62576 16.2069 5.88978 16.2069C6.1538 16.2069 6.41521 16.2592 6.65892 16.3607C6.90264 16.4623 7.12384 16.6111 7.30976 16.7985C7.49512 16.9854 7.64177 17.207 7.74129 17.4507C7.84082 17.6944 7.89127 17.9553 7.88975 18.2185C7.89932 18.4772 7.85856 18.7353 7.76975 18.9785C7.67993 19.2267 7.53662 19.4521 7.34999 19.6387C7.16335 19.8254 6.93796 19.9687 6.68977 20.0585C6.45037 20.1643 6.19152 20.2189 5.92978 20.2189C5.66804 20.2189 5.40919 20.1643 5.16979 20.0585C4.9216 19.9687 4.6962 19.8254 4.50957 19.6387C4.32293 19.4521 4.17963 19.2267 4.0898 18.9785C3.97784 18.7411 3.92305 18.4809 3.92981 18.2185C3.91999 18.0854 3.91999 17.9517 3.92981 17.8185C3.9517 17.6932 3.99214 17.5719 4.0498 17.4585C4.09358 17.3311 4.15411 17.21 4.2298 17.0985Z" fill="#272928"/>
            <path d="M2.88764 1.02163C3.80047 0.494989 4.83592 0.218076 5.88978 0.21875C7.29684 0.229668 8.65531 0.73477 9.72771 1.64576C10.8001 2.55675 11.5182 3.81568 11.7565 5.20246C11.9948 6.58925 11.7381 8.01566 11.0313 9.23236C10.3244 10.4491 9.21247 11.3786 7.88975 11.8586V12.2186C7.88975 12.749 7.67904 13.2577 7.30397 13.6328C6.92891 14.0078 6.4202 14.2186 5.88978 14.2186C5.35935 14.2186 4.85065 14.0078 4.47558 13.6328C4.10052 13.2577 3.88981 12.749 3.88981 12.2186V10.2186C3.88981 9.68819 4.10052 9.17949 4.47558 8.80442C4.85065 8.42935 5.35935 8.21864 5.88978 8.21864C6.4202 8.21864 6.92891 8.00793 7.30397 7.63286C7.67904 7.2578 7.88975 6.74909 7.88975 6.21867C7.88975 5.68824 7.67904 5.17954 7.30397 4.80448C6.92891 4.42941 6.4202 4.2187 5.88978 4.2187C5.53736 4.2173 5.19084 4.30905 4.88529 4.48465C4.57973 4.66025 4.32601 4.91348 4.1498 5.21868C4.02477 5.45769 3.85269 5.66894 3.6439 5.83971C3.43512 6.01049 3.19395 6.13727 2.93489 6.21242C2.67584 6.28758 2.40426 6.30955 2.1365 6.27703C1.86873 6.2445 1.61031 6.15815 1.37678 6.02316C1.14326 5.88817 0.939447 5.70734 0.777618 5.49155C0.615789 5.27575 0.499283 5.02945 0.43511 4.76746C0.370938 4.50547 0.360425 4.23321 0.404202 3.96705C0.44798 3.70089 0.545143 3.44634 0.689849 3.21871C1.21678 2.30604 1.9748 1.54826 2.88764 1.02163Z" fill="#272928"/>
        </svg>
    </div>
}

function DialogFeedback (props: {close: any}) {
    return <div className={styles['dialog-feedback']}>
        <div className={styles['header']}>
            <div>Submit feedback</div>
            <svg
                onClick={props.close}
                width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#F8F8F8"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.52858 7.52864C7.78892 7.26829 8.21103 7.26829 8.47138 7.52864L12 11.0572L15.5286 7.52864C15.7889 7.26829 16.211 7.26829 16.4714 7.52864C16.7317 7.78899 16.7317 8.2111 16.4714 8.47145L12.9428 12L16.4714 15.5286C16.7317 15.789 16.7317 16.2111 16.4714 16.4714C16.211 16.7318 15.7889 16.7318 15.5286 16.4714L12 12.9428L8.47138 16.4714C8.21103 16.7318 7.78892 16.7318 7.52858 16.4714C7.26823 16.2111 7.26823 15.789 7.52858 15.5286L11.0572 12L7.52858 8.47145C7.26823 8.2111 7.26823 7.78899 7.52858 7.52864Z" fill="#9B9B9B"/>
            </svg>
        </div>

        <div className={styles['des']}>If you encounter any issues while using Social Layer, please contact us via email.</div>
        <Link className={styles['btn']} href="mailto:hi@sola.day" target="_blank">hi@sola.day</Link>
        <Link className={styles['btn']} href="https://tally.so/r/w8ePXO"  target="_blank">Issues Report</Link>
    </div>
}
